import { ComplexFilter } from '@core/api/core';
import {
	StoreAsyncItem,
	checkAsyncAction,
	createAsyncInitialState,
	createReducer,
	forceFetchingState,
	invalidateStateFromAction,
} from '@flux';
import { MergeFiltersOptions } from '@pl/actions';
import { mainTypes } from '@pl/actions/types';
import {
	MODULE_PREFERENCE_CODE,
	OperationDateType,
	OperationDirection,
	OperationPersonal,
	OperationSorting,
	OperationStatus,
} from '@pl/model';
import { createDefaultPeriod } from '@utils/date';
import { everyKeyIsFalse } from '@utils/object';

export type MainOperationsState = {
	businessUnitsFilter: ComplexFilter;
	cashflowItemsFilter: ComplexFilter;
	cashflowTypesFilter: ComplexFilter;
	counterpartiesFilter: ComplexFilter;
	dateRange: DateRange;
	dateSorting: OperationSorting;
	dateType: OperationDateType;
	directionFilter: ComplexFilter<Record<OperationDirection, boolean>>;
	employeesFilter: ComplexFilter;
	fundsRegistersFilter: ComplexFilter;
	fundsRegisterStatistics: StoreAsyncItem<Array<FundsRegisterStatistics>>;
	isIncludesVAT: boolean;
	isSeparateVAT: boolean;
	operations: StoreAsyncItem<Array<PLOperationBrief>>;
	personalFilter: ComplexFilter<Record<OperationPersonal, boolean>>;
	preferences: StoreAsyncItem<Record<MODULE_PREFERENCE_CODE, any>>;
	projectsFilter: ComplexFilter;
	statusFilter: ComplexFilter<Record<OperationStatus, boolean>>;
	tenantEntitiesFilter: ComplexFilter;
	textFilter: string;
};

const initialState: MainOperationsState = {
	businessUnitsFilter: new ComplexFilter(),
	cashflowItemsFilter: new ComplexFilter(),
	cashflowTypesFilter: new ComplexFilter(),
	counterpartiesFilter: new ComplexFilter(),
	dateRange: createDefaultPeriod('month'),
	dateSorting: OperationSorting.DATE_DESC,
	dateType: OperationDateType.CASHFLOW,
	directionFilter: new ComplexFilter({
		value: {
			[OperationDirection.INCOME]: true,
			[OperationDirection.EXPENSE]: true,
			[OperationDirection.TRANSFER]: true,
		},
	}),
	employeesFilter: new ComplexFilter(),
	fundsRegistersFilter: new ComplexFilter(),
	fundsRegisterStatistics: createAsyncInitialState([]),
	isIncludesVAT: true,
	isSeparateVAT: true,
	operations: createAsyncInitialState([]),
	personalFilter: new ComplexFilter({
		value: {
			personal: true,
			business: true,
		},
	}),
	preferences: createAsyncInitialState({
		[MODULE_PREFERENCE_CODE.DIRECTION]: null,
		[MODULE_PREFERENCE_CODE.DATE_TYPE]: null,
		[MODULE_PREFERENCE_CODE.CASHFLOW_TYPE]: null,
	}),
	projectsFilter: new ComplexFilter(),
	statusFilter: new ComplexFilter({
		value: {
			[OperationStatus.FACT]: true,
			[OperationStatus.PLAN]: true,
		},
	}),
	tenantEntitiesFilter: new ComplexFilter(),
	textFilter: '',
};

const mainOperationsReducer = createReducer<MainOperationsState>(initialState, {
	[mainTypes.FETCH_FUNDS_REGISTER_STATISTICS]: (action: AsyncAction<Array<FundsRegisterStatistics>>, state) => {
		return {
			fundsRegisterStatistics: checkAsyncAction(action, state.fundsRegisterStatistics),
		};
	},
	[mainTypes.FETCH_MODULE_PREFERENCES]: (action: AsyncAction<Record<MODULE_PREFERENCE_CODE, string>>, state) => {
		const preferences = checkAsyncAction(action, state.preferences).item;
		const directionFilter = preferences[MODULE_PREFERENCE_CODE.DIRECTION] || state.directionFilter.value;
		const dateType = preferences[MODULE_PREFERENCE_CODE.DATE_TYPE] || state.dateType;
		const cashflowTypesFilter = preferences[MODULE_PREFERENCE_CODE.CASHFLOW_TYPE] || state.cashflowTypesFilter;

		return {
			preferences: checkAsyncAction(action, state.preferences),
			directionFilter: new ComplexFilter({ value: directionFilter }),
			dateType,
			cashflowTypesFilter,
			operations: forceFetchingState(action, state.operations),
		};
	},
	[mainTypes.FETCH_OPERATIONS]: (action: AsyncAction<Array<PLOperationBrief>>, state) => {
		return {
			operations: checkAsyncAction(action, state.operations),
		};
	},
	[mainTypes.GROUP_BY_DATE_TYPE]: (action: StaticAction<OperationDateType>, state) => {
		return {
			dateType: action.value,
			operations: invalidateStateFromAction(action, state.operations),
		};
	},
	[mainTypes.INVALIDATE_OPERATIONS]: (action: StaticAction, state) => {
		return {
			operations: invalidateStateFromAction(action, state.operations),
		};
	},
	[mainTypes.MERGE_FILTERS]: (action: StaticAction<MergeFiltersOptions>, state) => {
		return {
			businessUnitsFilter: new ComplexFilter(),
			cashflowItemsFilter: action.value.cashflowItem || new ComplexFilter(),
			cashflowTypesFilter: new ComplexFilter(),
			counterpartiesFilter: action.value.counterparty || new ComplexFilter(),
			dateRange: action.value.dateRange || state.dateRange,
			directionFilter:
				action.value.direction ||
				new ComplexFilter({
					value: {
						[OperationDirection.EXPENSE]: true,
						[OperationDirection.INCOME]: true,
						[OperationDirection.TRANSFER]: true,
					},
				}),
			employeesFilter: new ComplexFilter(),
			fundsRegistersFilter: new ComplexFilter(),
			operations: invalidateStateFromAction(action, state.operations),
			personalFilter: new ComplexFilter({
				value: {
					business: true,
					personal: true,
				},
			}),
			projectsFilter: action.value.project || new ComplexFilter(),
			statusFilter: new ComplexFilter({
				value: {
					[OperationStatus.FACT]: true,
					[OperationStatus.PLAN]: true,
				},
			}),
			tenantEntitiesFilter: action.value.tenantEntity || new ComplexFilter(),
			textFilter: '',
		};
	},
	[mainTypes.RESET_FILTERS]: (action: StaticAction, state) => {
		return {
			businessUnitsFilter: new ComplexFilter(),
			cashflowItemsFilter: new ComplexFilter(),
			cashflowTypesFilter: new ComplexFilter(),
			counterpartiesFilter: new ComplexFilter(),
			directionFilter: new ComplexFilter({
				value: {
					[OperationDirection.EXPENSE]: true,
					[OperationDirection.INCOME]: true,
					[OperationDirection.TRANSFER]: true,
				},
			}),
			employeesFilter: new ComplexFilter(),
			fundsRegistersFilter: new ComplexFilter(),
			operations: invalidateStateFromAction(action, state.operations),
			personalFilter: new ComplexFilter({
				value: {
					business: true,
					personal: true,
				},
			}),
			projectsFilter: new ComplexFilter(),
			statusFilter: new ComplexFilter({
				value: {
					[OperationStatus.FACT]: true,
					[OperationStatus.PLAN]: true,
				},
			}),
			tenantEntitiesFilter: new ComplexFilter(),
			textFilter: '',
		};
	},
	[mainTypes.SET_BUSINESS_UNITS_FILTER]: (action: StaticAction<ComplexFilter>, state) => {
		return {
			businessUnitsFilter: new ComplexFilter({ ...action.value }),
			operations: invalidateStateFromAction(action, state.operations),
		};
	},
	[mainTypes.SET_CASHFLOW_ITEMS_FILTER]: (action: StaticAction<ComplexFilter>, state) => {
		return {
			cashflowItemsFilter: new ComplexFilter({ ...action.value }),
			operations: invalidateStateFromAction(action, state.operations),
		};
	},
	[mainTypes.SET_CASHFLOW_TYPES_FILTER]: (action: StaticAction<ComplexFilter>, state) => {
		return {
			cashflowTypesFilter: new ComplexFilter({ ...action.value }),
			operations: invalidateStateFromAction(action, state.operations),
		};
	},
	[mainTypes.SET_COUNTERPARTIES_FILTER]: (action: StaticAction<ComplexFilter>, state) => {
		return {
			counterpartiesFilter: new ComplexFilter({ ...action.value }),
			operations: invalidateStateFromAction(action, state.operations),
		};
	},
	[mainTypes.SET_DATE_RANGE]: (action: StaticAction<DateRange>, state) => {
		return {
			dateRange: action.value,
			operations: invalidateStateFromAction(action, state.operations),
		};
	},
	[mainTypes.SET_DATE_SORTING]: (action: StaticAction<OperationSorting>) => {
		return {
			dateSorting: action.value,
		};
	},
	[mainTypes.SET_EMPLOYEES_FILTER]: (action: StaticAction<ComplexFilter>, state) => {
		return {
			employeesFilter: new ComplexFilter({ ...action.value }),
			operations: invalidateStateFromAction(action, state.operations),
		};
	},
	[mainTypes.SET_FILTER_BY_DIRECTION]: (
		action: StaticAction<ComplexFilter<Record<OperationDirection, boolean>>>,
		state,
	) => {
		const filter = new ComplexFilter({
			value: {
				...state.directionFilter.value,
				...action.value.value,
			},
		});
		const isLoaded = !everyKeyIsFalse(state.directionFilter.value);

		return {
			directionFilter: filter,
			operations: invalidateStateFromAction(action, { ...state.operations, isLoaded }),
		};
	},
	[mainTypes.SET_FILTER_BY_PERSONAL]: (
		action: StaticAction<ComplexFilter<Record<OperationPersonal, boolean>>>,
		state,
	) => {
		const filter = new ComplexFilter({
			value: {
				...state.personalFilter.value,
				...action.value.value,
			},
		});
		const isLoaded = !everyKeyIsFalse(state.personalFilter.value);

		return {
			personalFilter: filter,
			operations: invalidateStateFromAction(action, { ...state.operations, isLoaded }),
		};
	},
	[mainTypes.SET_FILTER_BY_STATUS]: (action: StaticAction<ComplexFilter<Record<OperationStatus, boolean>>>, state) => {
		const filter = new ComplexFilter({
			value: {
				...state.statusFilter.value,
				...action.value.value,
			},
		});
		const isLoaded = !everyKeyIsFalse(state.statusFilter.value);

		return {
			statusFilter: filter,
			operations: invalidateStateFromAction(action, { ...state.operations, isLoaded }),
		};
	},
	[mainTypes.SET_FILTER_BY_TEXT]: (action: StaticAction<string>) => {
		return {
			textFilter: action.value,
		};
	},
	[mainTypes.SET_FUNDS_REGISTERS_FILTER]: (action: StaticAction<ComplexFilter>, state) => {
		return {
			fundsRegistersFilter: new ComplexFilter({ ...action.value }),
			operations: invalidateStateFromAction(action, state.operations),
		};
	},
	[mainTypes.SET_IS_INCLUDES_VAT]: (action: StaticAction<boolean>, state) => {
		return {
			isIncludesVAT: action.value,
			operations: invalidateStateFromAction(action, state.operations),
		};
	},
	[mainTypes.SET_IS_SEPARATE_VAT]: (action: StaticAction<boolean>, state) => {
		return {
			isSeparateVAT: action.value,
			operations: invalidateStateFromAction(action, state.operations),
		};
	},
	[mainTypes.SET_PROJECTS_FILTER]: (action: StaticAction<ComplexFilter>, state) => {
		return {
			projectsFilter: new ComplexFilter({ ...action.value }),
			operations: invalidateStateFromAction(action, state.operations),
		};
	},
	[mainTypes.SET_TENANT_ENTITIES_FILTER]: (action: StaticAction<ComplexFilter>, state) => {
		return {
			operations: invalidateStateFromAction(action, state.operations),
			tenantEntitiesFilter: new ComplexFilter({ ...action.value }),
		};
	},
});

export default mainOperationsReducer;
